<template>
  <div v-if="visible == true" :class="computedClass" :id="id">
    <div class="col-12 col-sm-5 col-md-4 col-lg-3">
      <a v-if="imgSrc" :href="imgRef" class="d-flex flex-sm-column">
        <img v-for="src in imgArray" :src="src" class="img-fluid mx-auto p-2" :style="imgStyle">
      </a>
    </div>

    <div class="col">
      <slot />
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    imgSrc: {
      type: String,
      required: false,
      default: null
    },
    imgRef: {
      type: String,
      required: false,
      default: null
    },
    imgWidth: {
      type: String,
      required: false,
      default: ""
    },
    id: {
      type: String,
      required: false,
      default: null
    },
    class: {
      type: String,
      required: false,
      default: null
    },
    visible: {
      type: Boolean,
      required: false,
      default: true
    }
  });

  const computedClass = computed(()=>{
    var classes = "row";
    if (props.class) {
      classes += " " + props.class;
    }
    return classes;
  });

  const imgArray = computed(()=>{
    var list  = props.imgSrc.split(" ");
    return list;
  });

  const imgStyle = computed(()=>{
    var style = ""
    if (props.imgWidth != "") {
      style += `width: ${props.imgWidth};`;
    } else {
      style += "width: 100%;";
    }
    if (imgArray.value.length < 2) {
      style = `width: auto; height: 12rem;`;
    } else {
      style += `height: auto;`;
    }
    return style;
  });
</script>

<style scoped>
  .col {
    padding-bottom: 4rem;
  }
</style>
